<div
	class="flex flex-col"
	cdkDrag
	cdkDragRootElement=".cdk-overlay-pane">
	<div class="flex flex-row justify-between">
		<button
			mat-icon-button
			cdkDragHandle
			[matTooltip]="'janus.dialog.drag-handler.tooltip' | translate">
			<mat-icon>open_with</mat-icon>
		</button>
		<button
			*ngIf="false"
			mat-icon-button
			(click)="updateModalWidth()"
			[matTooltip]="'janus.dialog.size.tooltip' | translate">
			<mat-icon>pages</mat-icon>
		</button>
		<button
			mat-icon-button
			mat-dialog-close
			[matTooltip]="'janus.dialog.close.tooltip' | translate">
			<mat-icon>close</mat-icon>
		</button>
	</div>
</div>

<div
	class="mx-4 overflow-auto"
	#protocolContainer
	id="protocolContainer"
	[innerHTML]="body | safeHtml"></div>
