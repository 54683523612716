import { AfterViewInit, Component, OnInit, ViewEncapsulation } from "@angular/core";
import Drawflow from "drawflow";
import { EZoom } from "../../../enums/ezoom";
import { EProtocolValidationStatus } from "../../../enums/eprotocol-validation-status";
import html2canvas from "html2canvas";
import { Protocol } from "../../../interfaces/protocols/protocol";
import { ActivatedRoute, Router } from "@angular/router";
import { ToolbarServiceService } from "../../../services/toolbar-service.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ProtocolViewerComponent } from "../../protocols/viewer/protocol-viewer/protocol-viewer.component";
import { first } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { ProtocolLightViewerComponent } from "../../protocols/viewer/protocol-light-viewer/protocol-light-viewer.component";
import { VideoRepositoryService } from "../../../repositories/video-repository.service";
import { ProtocolContentDraft } from "../../../interfaces/protocols/protocol-content-draft";
import { QrCodeService } from "../../../services/qr-code.service";
import { ProtocolGlassesService } from "../../../services/protocol-glasses.service";
import { MatCard, MatCardContent } from "@angular/material/card";
import { NgClass } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { ProtocolService } from "../../../services/protocol.service";

@Component({
	selector: "app-create-glasses-protocol",
	templateUrl: "./create-glasses-protocol.component.html",
	styleUrls: ["./create-glasses-protocol.component.scss"],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [MatIcon, NgClass, MatCard, MatCardContent, NgxTolgeeModule],
})
export class CreateGlassesProtocolComponent implements OnInit, AfterViewInit {
	protocolId: number = null;
	protocol: Protocol = null;
	protocolContent: ProtocolContentDraft = null;
	protocolValidationStatus = EProtocolValidationStatus.UNKNOWN;
	protocolViewerRef: MatDialogRef<ProtocolViewerComponent> = null;
	editor: any;
	EZoom = EZoom;
	EProtocolValidationStatus = EProtocolValidationStatus;

	constructor(
		private route: ActivatedRoute,
		private toolbarService: ToolbarServiceService,
		private router: Router,
		private toastr: ToastrService,
		private translate: TranslateService,
		private manager: MitpManagerService,
		private dialog: MatDialog,
		private videoRepository: VideoRepositoryService,
		private qrCodeService: QrCodeService,
		private protocolGlassService: ProtocolGlassesService,
		private protocolService: ProtocolService
	) {}

	ngOnInit(): void {
		this.protocolId = this.route.snapshot.params["id"];
		this.protocol = history.state.data as Protocol;
		if (this.protocol == null) {
			this.getProtocolMeta();
		} else {
			this.updateToolbar();
		}
		this.protocolService
			.getDraftProtocol(this.protocolId)
			.pipe(first())
			.subscribe((content) => {
				this.protocolContent = content;
			});
	}

	getProtocolMeta() {
		this.protocolService
			.getProtocolById(this.protocolId)
			.pipe(first())
			.subscribe((protocol) => {
				this.protocol = protocol;
				this.updateToolbar();
			});
	}

	private updateToolbar() {
		this.toolbarService.protocolSubject.next(this.protocol);
	}

	ngAfterViewInit(): void {
		const id = document.getElementById("drawflow");
		this.editor = new Drawflow(id);
		console.log(this.editor);
		let dataToImport = {
			drawflow: {
				Home: {
					data: {
						"1": {
							id: 1,
							name: "mitp-glasses-start",
							data: {},
							class: "mitp-glasses-start",
							html: '<div><div class="title-box">Start</div></div>',
							typenode: false,
							inputs: {},
							outputs: { output_1: { connections: [] } },
							pos_x: 50,
							pos_y: 50,
						},
					},
				},
			},
		};
		this.manager.showLoading(true);
		this.protocolGlassService
			.getGlassesProtocol(this.protocolId)
			.pipe(first())
			.subscribe((protocol) => {
				if (protocol !== "") {
					dataToImport = JSON.parse(protocol);
				}
				this.editor.start();
				this.editor.import(dataToImport);
				this.manager.showLoading(false);
			});
		this.editor.on("nodeCreated", function (id) {
			console.log("Node created " + id);
		});

		this.editor.on("nodeRemoved", function (id) {
			console.log("Node removed " + id);
		});

		this.editor.on("nodeSelected", function (id) {
			console.log("Node selected " + id);
		});

		this.editor.on("moduleCreated", function (name) {
			console.log("Module Created " + name);
		});

		this.editor.on("moduleChanged", function (name) {
			console.log("Module Changed " + name);
		});

		this.editor.on("connectionCreated", function (connection) {
			console.log("Connection created");
			console.log(connection);
		});

		this.editor.on("connectionRemoved", function (connection) {
			console.log("Connection removed");
			console.log(connection);
		});

		this.editor.on("nodeMoved", function (id) {
			console.log("Node moved " + id);
		});

		this.editor.on("translate", function (position) {
			console.log("Translate x:" + position.x + " y:" + position.y);
		});

		this.editor.on("addReroute", function (id) {
			console.log("Reroute added " + id);
		});

		this.editor.on("removeReroute", function (id) {
			console.log("Reroute removed " + id);
		});
	}

	drag(ev) {
		ev.dataTransfer.setData("node", ev.target.getAttribute("data-node"));
	}

	allowDrop(ev) {
		ev.preventDefault();
	}

	drop(ev) {
		ev.preventDefault();
		const data = ev.dataTransfer.getData("node");
		this.addNodeToDrawFlow(data, ev.clientX, ev.clientY);
	}

	addNodeToDrawFlow(name, pos_x, pos_y) {
		if (this.editor.editor_mode === "fixed") {
			return false;
		}
		pos_x =
			pos_x * (this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)) -
			this.editor.precanvas.getBoundingClientRect().x *
				(this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom));
		pos_y =
			pos_y * (this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)) -
			this.editor.precanvas.getBoundingClientRect().y *
				(this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom));

		switch (name) {
			case "mitp-glasses-start": {
				const item = this.getStartStep();
				this.editor.addNode("mitp-glasses-start", 0, 1, pos_x, pos_y, "mitp-glasses-start", {}, item, false);
				break;
			}
			case "mitp-glasses-step-text": {
				const item = this.getTextStep();
				this.editor.addNode(
					"mitp-glasses-step-text",
					1,
					1,
					pos_x,
					pos_y,
					"mitp-glasses-step-text",
					{ text: "" },
					item,
					false
				);
				break;
			}
			case "mitp-glasses-step-video": {
				const item = this.getVideoStep();
				this.editor.addNode(
					"mitp-glasses-step-video",
					1,
					1,
					pos_x,
					pos_y,
					"mitp-glasses-step-video",
					{ channel: "", start: 0, end: 0 },
					item,
					false
				);
				break;
			}
			case "mitp-glasses-step-countdown": {
				const item = this.getCountdownStep();
				this.editor.addNode(
					"mitp-glasses-step-countdown",
					1,
					1,
					pos_x,
					pos_y,
					"mitp-glasses-step-countdown",
					{ duration: 1, autoStart: true },
					item,
					false
				);
				break;
			}
			case "mitp-glasses-step-decision-algo": {
				const item = this.getDecisionTreeStep();
				this.editor.addNode(
					"mitp-glasses-step-decision-algo",
					1,
					1,
					pos_x,
					pos_y,
					"mitp-glasses-step-decision-algo",
					{ algo: "" },
					item,
					false
				);
				break;
			}
		}
	}

	save(): void {
		this.manager.showLoading(true);
		const output = JSON.stringify(this.editor.export());
		this.protocolGlassService
			.saveGlassesProtocol(this.protocolId, output)
			.pipe(first())
			.subscribe({
				next: (res) => {
					if (res.status === 204) {
						this.toastr.success(this.translate.instant("EDIT-PROTOCOL_MESSAGE_SUCCESS"));
					} else {
						this.toastr.error(this.translate.instant("core.message.error.unknown"));
					}
					this.manager.showLoading(false);
				},
				error: () => {
					this.manager.showLoading(false);
				},
			});
	}

	changeView(value: EZoom): void {
		switch (value) {
			case EZoom.ZOOM_IN:
				this.editor.zoom_in();
				break;
			case EZoom.ZOOM_OUT:
				this.editor.zoom_out();
				break;
			case EZoom.DEFAULT:
				this.editor.zoom_reset();
				break;
		}
	}

	exportImage(): void {
		html2canvas(document.querySelector("#drawflow")).then((canvas) => {
			const img = canvas.toDataURL("image/png");
			const link = document.createElement("a");
			link.download = "test.png";
			link.href = img;
			link.click();
		});
	}

	goBackToProtocol(): void {
		this.router.navigate(["protocols", this.protocolId, "edit"]).then();
	}

	viewProtocol(): void {
		if (this.protocolViewerRef === null) {
			this.dialog.open(ProtocolLightViewerComponent, {
				disableClose: true,
				hasBackdrop: false,
				width: "600px",
				height: "60%",
				data: this.protocolId,
				autoFocus: false,
			});
		} else {
			this.protocolViewerRef.close();
		}
	}

	private getStartStep(): string {
		return `
		  <div>
			<div class="title-box">Start</div>
		  </div>
		  `;
	}

	private getVideoStep(): string {
		let videoItemsString = "";
		this.videoRepository.getVideos().forEach((video) => {
			videoItemsString += `<option value="${video.id}">${video.label}</option>`;
		});
		return `
		<div>
			<div class="title-box">Vidéo</div>
			<div class="box">
			  <p>Choisissez une vidéo</p>
			  <select df-channel>
				${videoItemsString}
			  </select>
			  <p>Début (secondes)</p>
              <input type="number" min="0" df-start>
              <p>Fin (secondes)</p>
              <input type="number" min="0" df-end>
			</div>
		  </div>`;
	}

	private getTextStep(): string {
		return `
          <div>
            <div class="title-box">Texte</div>
            <div class="box">
              <p>Texte</p>
            <textarea df-text></textarea>
            </div>
          </div>
          `;
	}

	private getCountdownStep(): string {
		return `
          <div>
            <div class="title-box">Compte-à-rebours</div>
            <div class="box">
              <p>Durée (minutes)</p>
              <input type="number" min="1" df-duration>
              <div style="display:flex; flex-direction: row;">
                <input type="checkbox" df-autoStart>
                <label>Démarrer auto</label>
			  </div>
            </div>
          </div>
          `;
	}

	private getDecisionTreeStep(): string {
		let algoItemsString = "";
		const parser = new DOMParser();
		const html = parser.parseFromString(this.protocolContent.content, "text/html");
		const algos = html.getElementsByTagName("mitp-algo");
		Array.from(algos).forEach((algo) => {
			const htmlAlgo = algo as HTMLElement;
			const algoId = htmlAlgo.getAttribute("id");
			const algoStructure = JSON.parse(htmlAlgo.dataset.dataAlgo);
			algoItemsString += `<option value="${algoId}">${algoStructure.title}</option>`;
		});

		return `
			<div>
			<div class="title-box">Algorithme</div>
			<div class="box">
			  <p>Choisissez un algorithme</p>
			  <select df-algo>
				${algoItemsString}
			  </select>
			</div>
		  </div>
		`;
	}

	validateProtocol(): void {
		this.manager.showLoading(true);
		this.protocolGlassService.validateGlassesProtocol(this.protocolId).subscribe({
			next: () => {
				this.protocolValidationStatus = EProtocolValidationStatus.VALID;
				this.manager.showLoading(false);
			},
			error: () => {
				this.protocolValidationStatus = EProtocolValidationStatus.NOT_VALID;
				this.manager.showLoading(false);
			},
		});
	}

	testOnGlasses(): void {
		this.qrCodeService.generateTestGlassesProtocolQrCode(this.protocolId);
	}
}
