import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { MITPService } from "./mitp.service";

@Injectable({
	providedIn: "root",
})
export class ProtocolGlassesService {
	constructor(
		private httpClient: HttpClient,
		private mitp: MITPService
	) {}

	saveGlassesProtocol(protocolId: number, content: string): Observable<HttpResponse<null>> {
		return this.httpClient.put<null>(
			this.mitp.Protocols() + `/${protocolId}/glasses/draft`,
			{ content: content },
			{
				observe: "response",
			}
		);
	}

	getGlassesProtocol(protocolId: number): Observable<string> {
		return this.httpClient.get<string>(this.mitp.Protocols() + `/${protocolId}/glasses/draft`);
	}

	validateGlassesProtocol(protocolId: number): Observable<null> {
		return this.httpClient.get<null>(this.mitp.Protocols() + `/${protocolId}/glasses/validate`);
	}
}
