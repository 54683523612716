import { Injectable } from "@angular/core";
import { Video } from "../entities/videos/video";
import { OrderByPipe } from "ngx-pipes";
import { VideosService } from "../modules/videos/videos.service";
import { first } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class VideoRepositoryService {
	videos: Video[] = [];

	constructor(videosService: VideosService, orderBy: OrderByPipe) {
		videosService
			.getVideos()
			.pipe(first())
			.subscribe((videos) => {
				this.videos = orderBy.transform(videos, "label");
			});
	}

	getVideos(): Video[] {
		return this.videos;
	}
}
