<div class="flex h-full w-full flex-col">
	<nav data-role="ribbonmenu">
		<ul class="tabs-holder">
			<li>
				<a href="#section-one">{{ "USERS_MENU" | translate }}</a>
			</li>
		</ul>
		<div class="content-holder">
			<div
				class="section"
				id="section-one">
				<!-- Edition section -->
				<div class="group">
					<button
						class="ribbon-button"
						(click)="save()">
						<span class="icon">
							<mat-icon>save</mat-icon>
						</span>
						<span class="caption">{{ "core.save" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="validateProtocol()">
						<span class="icon">
							<mat-icon
								[ngClass]="{
									'text-red-600': protocolValidationStatus === EProtocolValidationStatus.NOT_VALID,
									'text-green-600': protocolValidationStatus === EProtocolValidationStatus.VALID,
								}"
								>traffic</mat-icon
							>
						</span>
						<span class="caption">Vérifier</span>
					</button>
					<button
						class="ribbon-button"
						(click)="testOnGlasses()">
						<span class="icon">
							<mat-icon svgIcon="glasses"></mat-icon>
						</span>
						<span class="caption">Tester</span>
					</button>
					<span class="title">Edition</span>
				</div>
				<!-- End Edition section -->
				<!-- Protocol section -->
				<div class="group">
					<button
						class="ribbon-button"
						(click)="goBackToProtocol()">
						<span class="icon">
							<mat-icon>arrow_back</mat-icon>
						</span>
						<span class="caption">Revenir au protocole</span>
					</button>
					<button
						class="ribbon-button"
						(click)="viewProtocol()">
						<span class="icon">
							<mat-icon>assignment</mat-icon>
						</span>
						<span class="caption">Consulter</span>
					</button>
					<span class="title">Protocole</span>
				</div>
				<!-- End Protocol section -->
				<!-- View section -->
				<div class="group">
					<button
						class="ribbon-button"
						(click)="changeView(EZoom.DEFAULT)">
						<span class="icon">
							<mat-icon>crop_free</mat-icon>
						</span>
						<span class="caption">Taille réelle</span>
					</button>
					<button
						class="ribbon-button"
						(click)="changeView(EZoom.ZOOM_IN)">
						<span class="icon">
							<mat-icon>zoom_in</mat-icon>
						</span>
						<span class="caption">Agrandir</span>
					</button>
					<button
						class="ribbon-button"
						(click)="changeView(EZoom.ZOOM_OUT)">
						<span class="icon">
							<mat-icon>zoom_out</mat-icon>
						</span>
						<span class="caption">Réduire</span>
					</button>
					<span class="title">Affichage</span>
				</div>
				<!-- End View section -->
				<!-- Export section -->
				<div class="group">
					<button
						class="ribbon-button"
						(click)="exportImage()">
						<span class="icon">
							<mat-icon>image</mat-icon>
						</span>
						<span class="caption">PNG</span>
					</button>
					<span class="title">Export</span>
				</div>
				<!-- End Export section -->
				<div class="group"></div>
			</div>
		</div>
	</nav>
	<div class="flex flex-col">
		<div>
			<div class="m-4 flex flex-row gap-x-4">
				<mat-card
					class="drag-drawflow"
					[draggable]="true"
					(dragstart)="drag($event)"
					data-node="mitp-glasses-start">
					<mat-card-content>Start</mat-card-content>
				</mat-card>
				<mat-card
					class="drag-drawflow"
					[draggable]="true"
					(dragstart)="drag($event)"
					data-node="mitp-glasses-step-text">
					<mat-card-content
						><div class="flex flex-row items-center gap-x-2">
							<mat-icon>article</mat-icon>
							<div>Texte</div>
						</div></mat-card-content
					>
				</mat-card>
				<mat-card
					class="drag-drawflow"
					[draggable]="true"
					(dragstart)="drag($event)"
					data-node="mitp-glasses-step-countdown">
					<mat-card-content
						><div class="flex flex-row items-center gap-x-2">
							<mat-icon>av_timer</mat-icon>
							<div>Compte-à-rebours</div>
						</div></mat-card-content
					>
				</mat-card>
				<mat-card
					class="drag-drawflow"
					[draggable]="true"
					(dragstart)="drag($event)"
					data-node="mitp-glasses-step-video">
					<mat-card-content
						><div class="flex flex-row items-center gap-x-2">
							<mat-icon>video_library</mat-icon>
							<div>Vidéo</div>
						</div></mat-card-content
					>
				</mat-card>
				<mat-card
					class="drag-drawflow"
					[draggable]="true"
					(dragstart)="drag($event)"
					data-node="mitp-glasses-step-decision-algo">
					<mat-card-content
						><div class="flex flex-row items-center gap-x-2">
							<mat-icon>account_tree</mat-icon>
							<div>Algorithme décisionnel</div>
						</div></mat-card-content
					>
				</mat-card>
			</div>
		</div>
	</div>
	<div
		class="flex h-full w-full"
		id="drawflow"
		(drop)="drop($event)"
		(dragover)="allowDrop($event)"
		#drawflow></div>
</div>
