import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreateGlassesProtocolComponent } from "./create-glasses-protocol/create-glasses-protocol.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../../guards/auth-guard.service";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";

const routes: Routes = [
	{
		path: "glasses",
		children: [
			{
				path: "",
				pathMatch: "full",
				canActivate: [AuthGuardService],
				component: CreateGlassesProtocolComponent,
			},
			{
				path: ":id/edit",
				pathMatch: "full",
				canActivate: [AuthGuardService],
				component: CreateGlassesProtocolComponent,
			},
		],
	},
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		MatCardModule,
		CreateGlassesProtocolComponent,
	],
})
export class GlassesModule {}
